
import { mapActions, mapGetters, mapMutations } from "vuex";
import ButtonCompare from "@/components/button/button-compare";
import ButtonAutoteka from "@/components/button/button-autoteka";
// import cardClick from "~/mixins/cardClick";


export default {
	// mixins:[cardClick],
	components: { ButtonAutoteka, ButtonCompare },
	computed: {
		...mapGetters({
			likesArray: 'favorite/favorite/likesArray',
			currentCar: 'modal/modal-choose/currentCar',
			settings: 'settings/settings/settings',
			isNight: "settings/settings/isNight"
		}),
		isEqual() {
			if (this.currentCar) {
				return this.currentCar.id === this.offer.id
			} else {
				return false
			}
		}
	},
	props: {
		isForm: {
			type: Boolean,
			default: false
		},
		long: {
			type: Boolean,
			default: false
		},
		offer: {
			type: Object,
			default: () => {
			}
		},
		url: {
			type: String,
			default: ''
		},
		choose: {
			type: Boolean,
			default: false
		},
	},
	methods: {
		...mapActions({
			liked: 'favorite/favorite/liked',
			openModal: 'modal/modal-main/openModal',
			closeModal: 'modal/modal-main/closeModal',
		}),
		...mapMutations({
			setCurrentCar: 'modal/modal-choose/SET_CURRENT_CAR',
			setIsOfferClick: 'filters/filters/SET_IS_OFFER_CLICK'

		}),
		callback() {
			let payload = {
				modal_component: 'modal-callback-main',
				modal_title: 'ОБРАТНЫЙ <span>ЗВОНОК</span>',
				modal_sub_title: `Мы работаем с 9:00 до 21:00. Оставьте заявку, и мы свяжемся с Вами в рабочее время.`
			}
			this.openModal(payload)
		},
		async chooseClick(offer) {
			this.setCurrentCar(offer)
			await this.closeModal()
		},
		async like() {
			await this.liked(this.offer.external_id)
		},
		async call() {
			let payload = {
				modal_data: this.offer,
				modal_component: 'modal-callback',
				modal_title: 'Обратный звонок',
				modal_sub_title: this.offer.name
			}
			await this.openModal(payload)
		},
		callPhone() {
			var myLink = document.createElement('a');
			myLink.setAttribute('href', 'tel:' + this.settings.phone);
			myLink.click();
		},
		async tradeIn(carInfo) {
			let payload = {
				modal_data: carInfo,
				modal_component: 'modal-tradeIn',
				modal_title: 'Заявка на Trade-In',
				modal_sub_title: carInfo.name
			}
			await this.openModal(payload)
		},
		async autoteka(carInfo) {

			let payload = {
				modal_data: carInfo,
				modal_component: 'modal-autoteka',
				modal_title: 'Отчет «Автотеки» от ' + carInfo.createdAt,
				modal_sub_title: carInfo.name
			}
			this.openModal(payload)
		},
		async credit() {
			let payload = {
				modal_data: this.offer,
				modal_component: 'modal-credit',
				// modal_component: 'modal-timer',

				modal_title: 'ЗАЯВКА НА <span>АВТОКРЕДИТ</span>',
				modal_sub_title: this.offer.name
			}
			await this.openModal(payload)

			// this.$router.push({
			// 	path: '/credit', query: {
			// 		mark: this.offer.mark.slug,
			// 		model: this.offer.folder.slug,
			// 		car: this.offer.external_id
			// 	}
			// })
		},


		async linkClick() {
			await this.closeModal()
			await this.setIsOfferClick(true)
			await this.$router.push(this.url)
		}
		// toCatalog() {
		// 	this.$router.push('/used')
		// }
	}
}
