import { render, staticRenderFns } from "./catalog-item-small-desktop2.vue?vue&type=template&id=33ba8ba6&"
import script from "./catalog-item-small-desktop2.vue?vue&type=script&lang=js&"
export * from "./catalog-item-small-desktop2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CatalogItemHoverSlider2: require('/home/d/didisher2/pioner-cars.ru/www/components/catalog/catalog-item/catalog-item-components/catalog-item-hover-slider2.vue').default,CatalogItemTitle: require('/home/d/didisher2/pioner-cars.ru/www/components/catalog/catalog-item/catalog-item-components/catalog-item-title.vue').default,CatalogItemTechList: require('/home/d/didisher2/pioner-cars.ru/www/components/catalog/catalog-item/catalog-item-components/catalog-item-tech-list.vue').default,CatalogItemPrice: require('/home/d/didisher2/pioner-cars.ru/www/components/catalog/catalog-item/catalog-item-components/catalog-item-price.vue').default,CatalogItemButtons: require('/home/d/didisher2/pioner-cars.ru/www/components/catalog/catalog-item/catalog-item-components/catalog-item-buttons.vue').default,SkeletonCardSmall: require('/home/d/didisher2/pioner-cars.ru/www/components/skeleton/skeleton-card/skeleton-card-small.vue').default})
