
import { mapActions, mapMutations } from 'vuex'
import filters from "~/mixins/filters";
import cardClick from "~/mixins/cardClick";
export default {
	mixins: [filters, cardClick],
	data() {
		return {
			sliderInit: false
		}
	},
	mounted() {
		this.$refs.slider_small_desc.addEventListener('contextmenu', event => event.preventDefault());
	},
	props: {
		isForm: {
			type: Boolean,
			default: false
		},
		choose: {
			type: Boolean,
			default: false
		},
		hasButtons: {
			type: Boolean,
			default: true
		},
		slide: {
			type: Boolean,
			default: false
		},
		vertival: {
			type: Boolean,
			default: false
		},
		offer: Object
	},
	methods: {
		...mapActions({
			closeModal: 'modal/modal-main/closeModal',
			closeModal: 'modal/modal-main/closeModal'
		}),

		...mapMutations({
			setIsOfferClick: 'filters/filters/SET_IS_OFFER_CLICK',
			setCurrentCar: 'modal/modal-choose/SET_CURRENT_CAR',

		}),
		preventLink(e) {
			e.preventDefault()
		},
		async cardlinkClick() {
			if (!this.choose) {
				await this.closeModal()
				await this.setIsOfferClick(true)
				await this.$router.push(this.currentUrl)
			} else {
				this.setCurrentCar(this.offer)
				await this.closeModal()


			}
		}
	},
	watch: {
		$route() {
			this.closeModal()
		}
	}


}
